import { withDependencies, multi } from '@wix/thunderbolt-ioc'
import { RendererPropsExtenderSym, IRendererPropsExtender } from '@wix/thunderbolt-symbols'
import { IRendererPropsImpl, RendererProps } from './types'

const rendererPropsImpl = (rendererPropsExtenders: Array<IRendererPropsExtender>): IRendererPropsImpl => {
	let rendererProps: RendererProps
	return {
		resolveRendererProps: async () => {
			const resolvedRendererPropsExtenders = await Promise.all(
				rendererPropsExtenders.map((extender) => extender.extendRendererProps())
			)
			rendererProps = resolvedRendererPropsExtenders.reduce(
				(acc, partialContext) => Object.assign(acc, partialContext),
				{}
			) as RendererProps
		},
		getRendererProps: () => rendererProps,
	}
}

export const RendererPropsImpl = withDependencies([multi(RendererPropsExtenderSym)], rendererPropsImpl)
