import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import { CurrentRouteInfoSymbol, IAppDidMountHandler, IRenderer, LifeCycle } from '@wix/thunderbolt-symbols'
import { RendererProps, BatchingStrategy, ClientRenderResponse, IRendererPropsImpl } from '../types'
import App from '../components/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { ICurrentRouteInfo } from 'feature-router'
import { RendererPropsSym, BatchingStrategySymbol } from '../symbols'

type ReactClientRendererFactory = (
	appDidMountHandlers: Array<IAppDidMountHandler>,
	currentRouteInfo: ICurrentRouteInfo,
	rendererProps: IRendererPropsImpl,
	batchingStrategy: BatchingStrategy
) => IRenderer<RendererProps, Promise<ClientRenderResponse>>

let appDidMountResolver: () => void
const appDidMountPromise = new Promise((resolve) => {
	appDidMountResolver = resolve
})

const reactClientRenderer: ReactClientRendererFactory = (
	appDidMountHandlers,
	currentRouteInfo,
	rendererProps,
	batchingStrategy
) => ({
	getRendererProps: rendererProps.getRendererProps,
	init: async () => {
		await rendererProps.resolveRendererProps()
	},
	render: async () => {
		const target = document.getElementById('site-container') as HTMLElement
		ReactDOM.hydrate(
			<React.StrictMode>
				<App
					{...rendererProps.getRendererProps()}
					batchingStrategy={batchingStrategy}
					onDidMount={appDidMountResolver}
				/>
			</React.StrictMode>,
			target
		)

		await appDidMountPromise
		appDidMountHandlers.map((appDidMountHandler) => appDidMountHandler.appDidMount(target))
		const route = currentRouteInfo.getCurrentRouteInfo()
		return {
			firstPageId: (route && route.pageId) || 'PROTECTED',
		}
	},
})

export const ReactClientRenderer = withDependencies(
	[multi(LifeCycle.AppDidMountHandler), CurrentRouteInfoSymbol, RendererPropsSym, BatchingStrategySymbol],
	reactClientRenderer
)
