import { ContainerModuleLoader, multi, withDependencies } from '@wix/thunderbolt-ioc'
import { DomReadySymbol, IAppWillMountHandler, IRenderer, LifeCycle, RendererSymbol } from '@wix/thunderbolt-symbols'
import { RendererProps } from 'feature-react-renderer'
import { NavigationInfoSym, Thunderbolt } from './types'
import { IThunderbolt } from './IThunderbolt'
import { createDomReadyPromise, WaitForDomReady } from './DomReady'
import { NavigationInfo } from './NavigationInfo'
import { taskify } from '@wix/thunderbolt-commons'

const ThunderboltImpl = (
	initializables: Array<IAppWillMountHandler>,
	renderer: IRenderer<RendererProps, any>
): IThunderbolt => {
	return {
		ready: async () => {
			const initThings = initializables.map((initializable) => taskify(() => initializable.appWillMount()))
			await Promise.all([renderer.init(), ...initThings])
		},
		render: () => {
			return renderer.render()
		},
		getRendererProps: renderer.getRendererProps,
	}
}

const thunderboltImpl = withDependencies([multi(LifeCycle.AppWillMountHandler), RendererSymbol], ThunderboltImpl)

export const site: ContainerModuleLoader = (bind) => {
	bind(Thunderbolt).to(thunderboltImpl)
	if (process.env.browser) {
		bind(DomReadySymbol).toConstantValue(createDomReadyPromise())
		bind(LifeCycle.AppWillMountHandler).to(WaitForDomReady)
	}
	const navigationInfo = new NavigationInfo()
	bind(LifeCycle.PageWillMountHandler).toConstantValue(navigationInfo)
	bind(NavigationInfoSym).toConstantValue(navigationInfo)
}
